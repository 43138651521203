export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_TASKS = "GET_ALL_TASKS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAIL = "CREATE_POST_FAIL";
export const CREATE_REPORT_FAIL = "CREATE_REPORT_FAIL";
export const GET_LIVE_POSTS_REQUEST = "GET_LIVE_POSTS_REQUEST";
export const GET_LIVE_POSTS = "GET_LIVE_POSTS";
export const GET_POST = "GET_POST";
export const GET_POST_REQUEST = "GET_POST_REQUEST";
export const GET_ALL_POSTS_REQUEST = "GET_ALL_POSTS_REQUEST";
export const GET_ALL_POSTS_SUCCESS = "GET_ALL_POSTS_SUCCESS";
export const GET_ALL_POSTS_FAIL = "GET_ALL_POSTS_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const SET_NOTIFICATIONS_REQUEST = "SET_NOTIFICATIONS_REQUEST";
export const SET_NOTIFICATIONS_SUCCESS = "SET_NOTIFICATIONS_SUCCESS";
export const DELET_COMMENT_REQUEST = "DELET_COMMENT_REQUEST";
export const DELET_COMMENT_SUCCESS = "DELET_COMMENT_SUCCESS";
export const DELET_COMMENT_FAIL = "DELET_COMMENT_FAIL";

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAIL = "ADD_COMMENT_FAIL";

export const GET_POSTS_FROM_LAST_WEEK = "GET_POSTS_FROM_LAST_WEEK";
export const GET_POSTS_FROM_LAST_WEEK_REQUEST = "GET_POSTS_FROM_LAST_WEEK_REQUEST";